import { HttpResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { Constants } from '@app/@shared/constants';
import { ProfileService } from '@app/@shared/profiles/profile.service';
import { ModalSceltaAslComponent } from '@app/microservizi/anagrafe/modal-scelta-asl.component';
import { DeleganteServiceService } from '@app/microservizi/fse/delegante-service.service';
import { HomeService } from '@app/microservizi/home/home.service';
import { IAssistito } from '@app/modello/assistito';
import { IPersona } from '@app/modello/persona';
import { NgbModal, NgbModalRef, NgbOffcanvas } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent implements OnInit {
  public isFseCollapsed = true;
  public isScreeningCollapsed = true;
  public isPrenotazioniCollapsed = true;
  public isPerLaMammaCollapsed = true;
  public isAnagrafeCollapsed = true;
  public isAreaPersonaleCollapsed = true;
  public isUtilityCollapsed = true;
  isExpanded: boolean = false;
  public delegante$: Observable<IPersona | null>;
  isFSE: boolean = false;
  isScreening: boolean = false;
  isPrenotazioni: boolean = false;
  isAnagrafe: boolean = false;
  isMedicinaBase: boolean = false;
  isUtilita: boolean = false;
  isPerLaMamma: boolean = false;
  isAreaPersonale: boolean = false;
  residentePotenza: boolean = false;
  linkSceltaMedico: string | '' = '';
  public modalRef: NgbModalRef | null = null;

  constructor(
    private routerModule: RouterModule,
    private offCanvas: NgbOffcanvas,
    private deleganteService: DeleganteServiceService,
    private router: Router,
    private servizioInfo: ProfileService,
    private route: ActivatedRoute,
    private modalService: NgbModal,
    private service: HomeService
  ) {
    this.delegante$ = deleganteService.delegante$;
    if (!this.isMobile()) {
      this.isExpanded = true;
      this.open();
    }
    this.handleItemMenu();
    this.initCollapse();
  }

  initCollapse(): void {
    if (this.router.url.split('/')[1] === 'prenotazioni') {
      this.isPrenotazioniCollapsed = false;
    } else if (this.router.url.split('/')[1] === 'fascicolo') {
      this.isFseCollapsed = false;
    } else if (this.router.url.split('/')[1] === 'screening') {
      this.isScreeningCollapsed = false;
    } else if (this.router.url.split('/')[1] === 'per-la-mamma') {
      this.isPerLaMammaCollapsed = false;
    } else if (this.router.url.split('/')[1] === 'area-personale') {
      this.isAreaPersonaleCollapsed = false;
    } else if (this.router.url.split('/')[1] === 'utility') {
      this.isUtilityCollapsed = false;
    } else {
      this.isAreaPersonaleCollapsed = false;
    }
  }

  accediASceltaMedico(): void {
    this.service.getAnagrafeAssistito().subscribe({
      next: (res: HttpResponse<IAssistito>) => {
        if (res.body?.aslDomicilio !== null) {
          if (res.body?.aslDomicilio.codiceAzienda === '201') {
            window.location.href = 'https://sceltamedico.aspbasilicata.it:8443/sceltamedico/';
          } else {
            window.location.href = 'https://sceltamedico.asmbasilicata.it:8443/sceltamedico';
          }
        } else {
          if (!this.modalService.hasOpenModals()) {
            this.modalRef = this.modalService.open(ModalSceltaAslComponent, { centered: true });
          }
        }
      },
      error: () => {
        if (!this.modalService.hasOpenModals()) {
          this.modalRef = this.modalService.open(ModalSceltaAslComponent, { centered: true });
        }
      },
    });
  }

  handleItemMenu(): void {
    this.isFSE = false;
    this.isScreening = false;
    this.isPrenotazioni = false;
    this.isUtilita = false;
    this.isPerLaMamma = false;
    this.servizioInfo.getProfileInfo().subscribe((info) => {
      if (info.microservices != null && info.microservices.length > 0) {
        if (info.microservices.includes(Constants.FSE)) {
          this.isFSE = true;
        }
        if (info.microservices.includes(Constants.SCREENING)) {
          this.isScreening = true;
        }
        if (info.microservices.includes(Constants.PRENOTAZIONE)) {
          this.isPrenotazioni = true;
        }
        if (info.microservices.includes(Constants.UTILITA)) {
          this.isUtilita = true;
        }
        if (info.microservices.includes(Constants.PER_LA_MAMMA)) {
          this.isPerLaMamma = true;
        }
        if (info.microservices.includes(Constants.AREA_PERSONALE)) {
          this.isAreaPersonale = true;
        }
      } else {
        this.isFSE = true;
        this.isScreening = true;
        this.isPrenotazioni = true;
        this.isPerLaMamma = true;
        this.isUtilita = true;
        this.isAreaPersonale = true;
      }
    });
  }

  handleSidebarToggle(): void {
    if (!this.isExpanded) {
      this.open();
      this.isExpanded = true;
    } else {
      if (!this.isCanvas()) {
        this.offCanvas.dismiss();
      } else {
        this.close();
      }
      this.isExpanded = false;
    }
    localStorage.setItem('expanded', JSON.stringify(this.isExpanded));
  }

  open(): void {
    if (!this.offCanvas.hasOpenOffcanvas()) {
      this.offCanvas.open('', {
        panelClass: 'd-none',
        backdropClass: 'offCanvasSidebar',
        scroll: true,
        beforeDismiss: () => {
          if (this.isCanvas()) {
            this.isExpanded = false;
            localStorage.setItem('expanded', JSON.stringify(this.isExpanded));
          }
          return true;
        },
      });
    }
  }

  close(): void {
    if (this.offCanvas.hasOpenOffcanvas() && this.isCanvas()) {
      this.offCanvas.dismiss();
    }
    localStorage.setItem('expanded', JSON.stringify(this.isExpanded));
  }

  tornaSuFse(): void {
    this.deleganteService.setDelegante(null);
    sessionStorage.setItem(Constants.Keys.Fse.ID_DELEGA, '');
    this.router.navigate(['home'], { replaceUrl: true });
  }

  ngOnInit(): void {
    const expande = localStorage.getItem('expanded');
    if (expande === 'true') {
      this.isExpanded = true;
      this.open();
    } else {
      this.isExpanded = false;
      this.close();
    }
  }

  isMobile(): boolean {
    return window.outerWidth < 1440;
  }

  isCanvas(): boolean {
    return window.outerWidth < 992;
  }
}
